<template>
  <b-form-select
    v-model="selected"
    :options="options"
    class="w-100"
  />
</template>

<script>
export default {
  name: 'BrokersList',

  props: {

  },

  data() {
    return {
      options: [

      ],

      selected: null,

      list: [],
    }
  },

  watch: {
    selected() {
      this.$emit('input', this.selected)
    },
  },

  async mounted() {
    await this.loadList()
  },

  methods: {
    async loadList() {
      this.list = await this.$api.getBrokers()

      console.log('brokers list', this.list)

      this.options = this.list.map(l => {
        return {
          value: l,
          text: `${l.ticker} ${l.minYear}-${l.maxYear} :${l.brokerId}`,
        }
      })

      if (this.selected === null) {
        this.selected = this.list[0]
      }

      this.$emit('loaded')
    },

    selectBroker({ ticker, brokerId }) {
      const selected = this.list.find(b => b.brokerId === brokerId && b.ticker === ticker)

      if (selected) {
        this.selected = selected

        this.$emit('input', this.selected)
      }
    },
  },
}
</script>

<style scoped>

</style>
