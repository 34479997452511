<template>
  <div class="run-settings">
    <label><b>Bot run</b></label>
    <run-list
      ref="runList"
      v-model="selectedRun"
    />

    <label class="mt-2"><b>Broker</b></label>
    <brokers-list
      ref="brokersList"
      v-model="selectedBroker"
      @loaded="brokersListLoadedHandler"
    />

    <!-- <p>hash: {{ selectedRun.hash }}</p> -->
    <!-- <p>broker: {{ selectedBroker.ticker }}</p> -->

    <label class="mt-2"><b>Run options</b></label>
    <dynamic-controls
      ref="controls"
      :data="controlsData"
    />

    <label
      v-if="selectedRun.meta"
      class="mt-2"
    ><b>Stats</b></label>
    <pre v-if="selectedRun.meta">
      {{ selectedRun.meta.customData.stats }}
    </pre>

    <b-button
      block
      variant="primary"
      size="sm"
      class="mt-2"
      @click="runHandler"
    >
      <span>Run bot</span>
      <b-spinner
        v-if="running"
        style="margin-left: 7px;"
        small
      />
    </b-button>
  </div>
</template>

<script>
import RunList from '@/components/RunList'
import BrokersList from '@/components/BrokersList'
import DynamicControls from '@/components/DynamicControls'

export default {
  components: {
    RunList,
    BrokersList,
    DynamicControls,
  },

  data() {
    return {
      selectedRun: {},
      selectedBroker: {},

      running: false,

      controlsData: [
        {
          name: 'stopDelta',
          label: 'Stop delta',
          type: 'input',
          props: {},
          value: 0.0002,
        },

        {
          name: 'takeDelta',
          label: 'Take delta',
          type: 'input',
          props: {},
          value: 0.002,
        },

        {
          name: 'patternMinCandle',
          label: 'Pattern Min Candle',
          type: 'input',
          props: {},
          value: 15,
        },

        {
          name: 'patternMaxCandle',
          label: 'Pattern Max Candle',
          type: 'input',
          props: {},
          value: 90,
        },

        // {
        //   name: 'input-1',
        //   label: 'Input Label',
        //   type: 'input',
        //   props: {},
        //   value: 0.001,
        // },

        // {
        //   name: 'input-2',
        //   label: 'Checkbox Label',
        //   type: 'checkbox',
        //   props: {},
        //   value: true,
        // },

        // {
        //   name: 'input-3',
        //   label: 'Select Label',
        //   type: 'select',
        //   props: {
        //     options: [
        //       {
        //         text: 'Option A',
        //         value: 0,
        //       },
        //       {
        //         text: 'Option B',
        //         value: 1,
        //       },
        //     ],
        //   },
        //   value: 0,
        // },

      ],
    }
  },

  watch: {
    selectedRun() {
      if (this.selectedRun.meta) {
        this.$refs.brokersList.selectBroker({
          brokerId: this.selectedRun.meta.customData.brokerId,
          ticker: this.selectedRun.meta.customData.ticker,
        })

        this.$emit('update', {
          selectedRun: this.selectedRun,
          selectedBroker: this.selectedBroker,
        })
      } else {
        console.error('Outdated run json file. No `customData` field.')
      }
    },
  },

  async  mounted() {

  },

  methods: {
    async runHandler() {
      this.running = true
      await this.$api.exporterRun({
        brokerId: this.selectedBroker.brokerId,
        ticker: this.selectedBroker.ticker,

        ...this.$refs.controls.getValues(),
      })

      await this.$refs.runList.loadList()

      this.running = false
    },

    brokersListLoadedHandler() {
      this.$refs.brokersList.selectBroker({
        brokerId: this.selectedRun.meta.customData.brokerId,
        ticker: this.selectedRun.meta.customData.ticker,
      })

      this.$emit('update', {
        selectedRun: this.selectedRun,
        selectedBroker: this.selectedBroker,
      })
    },
  },
}
</script>

<style scoped lang="stylus">
  .run-settings
    color #000
</style>