<template>
  <div class="dynamic-controls">
    <div
      v-for="control in data"
      :key="control.name"
      class="dynamic-controls__item"
    >
      <label
        v-if="control.type!== 'checkbox'"
        :for="'b-' + control.name"
      >
        {{ control.label || control.name }}
      </label>

      <component
        :is="'b-' + control.type"
        :id="'b-' + control.name"
        v-model="model[control.name]"
        v-bind="control.props"
      >
        <template v-if="control.type === 'checkbox'">
          {{ control.label || control.name }}
        </template>
      </component>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },

  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      mapComponents: {
        input: 'b-input',
        select: 'b-select',
        checkbox: 'b-checkbox',
      },

      flag: true,

      model: {

      },
    }
  },

  beforeMount() {
    this.fillTheModel()
  },

  async  mounted() {

  },

  methods: {
    async fillTheModel() {
      this.data.forEach(element => {
        this.model[element.name] = element.value

        if (element.type == 'input') {
          element.props.size = 'sm'
          element.props.type = 'number'
        }
      })
    },

    getValues() {
      return JSON.parse(JSON.stringify(this.model))
    },
  },
}
</script>

<style scoped lang="stylus">
  .dynamic-controls
    color #000

    &__item
      margin 8px 0

      label
        display block
        margin-bottom 0px

      &:first-child
        margin-top 0

      >>>.custom-checkbox
        display flex
        align-items center

        label
          margin-left: 6px
</style>