<template>
  <div class="test vh-100">
    <chart
      ref="chart"
      @range="rangeChangeHandler"
    />

    <div class="layout">
      <b-icon
        :scale="1.4"
        icon="gear-wide-connected"
        @click="settingsPopupShown = !settingsPopupShown"
      />

      <popup-container
        v-show="settingsPopupShown"
        class="settings-popup"
      >
        <run-settings
          ref="settings"
          @update="botRunUpdate"
        />
      </popup-container>
    </div>
  </div>
</template>

<script>

import Chart from '@/components/Chart'
import PopupContainer from '@/components/PopupContainer'
import RunSettings from '@/components/RunSettings'

export default {
  components: {
    Chart,
    PopupContainer,
    RunSettings,
  },

  data() {
    return {
      settingsPopupShown: true,
    }
  },

  methods: {
    async rangeChangeHandler(range) {
      const { drawingData } = await this.loadData(range)

      this.$refs.chart.clearFigures()
      this.$refs.chart.drawFigures(drawingData)
    },

    async loadData([startDate, endDate]) {
      const figures = await this.$api.getExporterRead({
        hash: this.$refs.settings.selectedRun.hash,
        startDate: startDate,
        endDate: endDate,
      })

      return figures
    },

    async botRunUpdate({ selectedRun, selectedBroker }) {
      if (selectedBroker.startDate) {
        this.$refs.chart.setBrokerData({
          ticker: selectedRun.meta.customData.ticker,
          brokerId: selectedRun.meta.customData.brokerId,
          startDate: selectedBroker.startDate,
          endDate: selectedBroker.startDate + 1000 * 60 * 2000,
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
  .layout
    position: fixed
    bottom: 32px
    left: 20px
    z-index 200
    color #fff

  .settings-popup
    position: absolute
    bottom 32px
    left: 0px
</style>
