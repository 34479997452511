<template>
  <b-form-select
    v-model="selected"
    :options="options"
    class="w-100"
  />
</template>

<script>
export default {
  name: 'RunsList',

  props: {

  },

  data() {
    return {
      options: [

      ],

      selected: '',
    }
  },

  watch: {
    selected() {
      this.$emit('input', this.selected)
    },
  },

  async  mounted() {
    await this.loadList()
  },

  methods: {
    async loadList() {
      const list = await this.$api.getExporterList()

      this.options = list.map(l => {
        return {
          value: l,
          text: l.meta !== undefined ? (l.meta.customData.ticker + ' — ' + new Date(l.date)) : '-- outdated format --',
        }
      })

      this.selected = list[0]
    },
  },
}
</script>

<style scoped>

</style>
