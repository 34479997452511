<template>
  <div
    class="popup-container"
    :style="'width:' + maxWidth + 'px'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PopupContainer',

  props: {
    /**
     * Max width
     */
    maxWidth: {
      type: Number,
      default: 320,
    },
  },

  data() {
    return {

    }
  },

  methods: {

  },
}
</script>

<style scoped lang="stylus">
  .popup-container
    border-radius 8px
    box-shadow: 0 4px 8px rgba(0,0,0,.06), 0 0 1px rgba(0,0,0,.25);
    background: #fff
    padding: 12px
    overflow-y: auto
    max-height: calc(100vh - 100px)
</style>
